import React, { useState } from "react";
import { Helmet } from "react-helmet";

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <>
      <Helmet>
        <title>
          Marketing Fundamentals & Digital Marketing Portfolio | Mannix Infotech
          Pvt. Ltd.
        </title>
        <meta
          name="description"
          content="Explore our marketing fundamentals and digital marketing portfolio at Mannix Infotech. Discover successful projects and strategies we’ve implemented."
        />
        <meta
          name="keyword"
          content="Digital marketing portfolio examples in Ahmedabad |
    Marketing fundamentals case studies in India |
     Mannix Infotech marketing portfolio review |
      Digital marketing services portfolio in Ahmedabad |
Marketing solutions provider in Ahmedabad|
   Digital marketing agency portfolio in Ahmedabad |
  Successful digital marketing projects in Ahmedabad|
     Effective marketing strategies for businesses|	
	Digital marketing success stories in India|
    Implemented digital marketing solutions for startups"
        />

        <link rel="canonical" href="https://www.mannix.in/portfolio" />
      </Helmet>

      <div>
        <section className="ops_portfolio_tabing">
          <div className="container">
            <div className="ops_portfolio_tabing_top"></div>
            <div className="ops_portfolio_tabing_outer">
              <div className="tab-menu ops_desktop_only d-md-block d-none fs-5 ">
                <ul>
                  <li className="tabs_first">
                    <a
                      href="/"
                      className={`tab-a active-a fs-2 ${
                        activeTab === "tab1" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tab1")}
                    >
                      All Projects
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      // href="/"
                      className={`tab-a ${
                        activeTab === "tab1" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tab1")}
                    >
                      Web Development
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      // href="/"
                      className={`tab-a ${
                        activeTab === "tab2" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tab2")}
                    >
                      App Development
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      // href="/"
                      className={`tab-a ${
                        activeTab === "tab3" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tab3")}
                    >
                      Logo Design
                    </a>
                  </li>
                  {/* Add more tabs as needed */}
                </ul>
              </div>
              <div className="tab-menu ops_mobile_only  d-sm-none ">
                <select
                  className="form-select "
                  style={{ paddingRight: "-100px" }}
                  onChange={(e) => handleTabClick(e.target.value)}
                  value={activeTab}
                >
                  <option value="tab1">Web Development</option>
                  <option value="tab2">App Development</option>
                  <option value="tab3">Logo Design</option>
                </select>
              </div>
              <div className="tab-content d-none d-md-block ">
                {activeTab === "tab1" && (
                  <div
                    id="tab1"
                    className=" d-flex justify-content-center flex-wrap gap-3"
                  >
                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../web1.png"
                              alt="web1"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "240px",
                            position: "relative",
                            top: "10px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "170px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Website</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        MINAXI PALACE HOTEL
                      </p>
                    </div>

                    <div>
                      {" "}
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../web2.png"
                              alt="web2"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "240px",
                            position: "relative",
                            top: "10px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "170px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Website</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        BALAJI MANUFACTURING COMPANY
                      </p>
                    </div>

                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../web3.png"
                              alt="web3"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "240px",
                            position: "relative",
                            top: "10px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "170px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Website</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        BALAJI MOBILE CARE
                      </p>
                    </div>

                    <div>
                      {" "}
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../web4.png"
                              alt="web4"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "240px",
                            position: "relative",
                            top: "10px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "170px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Website</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        MAHVEER LIGHTS
                      </p>
                    </div>

                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../app1.png"
                              alt="app1"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "240px",
                            position: "relative",
                            top: "10px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "170px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Website</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        VIDYA BHAWAN SCHOOL
                      </p>
                    </div>
                    <div>
                      {" "}
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="./Images/arokeenterprise.png"
                              alt="arokeenterprise"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "240px",
                            position: "relative",
                            top: "10px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "170px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Website</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        Arok Enterprise
                      </p>
                    </div>
                    <div>
                      {" "}
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="./Images/anjalimetals.png"
                              alt="anjalimetals"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "240px",
                            position: "relative",
                            top: "10px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "170px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Website</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        Anjali Metals
                      </p>
                    </div>
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div
                    id="tab2"
                    className="container d-flex justify-content-center flex-wrap gap-3"
                  >
                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../app2.png"
                              alt="app2"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "240px",
                            position: "relative",
                            top: "10px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "170px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Application</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        THE NAISHA HYPERMART
                      </p>
                    </div>

                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../app3.png"
                              alt="app3"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "240px",
                            position: "relative",
                            top: "10px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "170px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Application</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        MINAXI PALACE HOTEL
                      </p>
                    </div>
                  </div>
                )}
                {activeTab === "tab3" && (
                  <div
                    id="tab3"
                    className="container d-flex justify-content-center flex-wrap gap-3"
                  >
                    <div className="">
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                          }}
                        >
                          <p>
                            <img
                              src="../logodesign2.png"
                              width="200px"
                              alt="logodesign2"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p className="fw-bold text-center logotext fs-5 mt-3 mb-4">
                        MINAXI PALACE
                      </p>
                    </div>
                    <div>
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                          }}
                        >
                          <p>
                            <img
                              src="../logodesign3.png"
                              width="200px"
                              alt="logodesign3"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p className="fw-bold text-center logotext fs-5 mt-3 mb-4">
                        VIDYABHAWAN
                      </p>
                    </div>
                    <div>
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                          }}
                        >
                          <p>
                            <img
                              src="../logodesign4.png"
                              width="200px"
                              alt="logodesign4"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        BALAJI MANUFACTURING
                      </p>
                    </div>
                    <div>
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                          }}
                        >
                          <p>
                            <img
                              src="../logodesign5.png"
                              width="200px"
                              alt="logodesign5"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        MAHAVEER LIGHTS
                      </p>
                    </div>
                    <div>
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                          }}
                        >
                          <p>
                            <img
                              src="../logodesign6.png"
                              width="200px"
                              alt="logodesign6"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        NAISHA HYPERMART
                      </p>
                    </div>
                    <div>
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                            height: "300px",
                          }}
                        >
                          <p>
                            <img
                              className="mt-5"
                              src="../logodesign7.png"
                              width="200px"
                              alt="logodesign7"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        BALAJI MOBILECARE
                      </p>
                    </div>
                    <div className="">
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                          }}
                        >
                          <p>
                            <img
                              src="../Images/logodesign8.jpg"
                              width="200px"
                              alt="logodesign8"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        HELTON LIGHTS
                      </p>
                    </div>
                    <div>
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                            height: "300px",
                          }}
                        >
                          <p>
                            <img
                              className="mt-1"
                              src="../Images/logodesign9.png"
                              width="200px"
                              alt="logodesign9"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        T-CONNECT GADGETS
                      </p>
                    </div>
                    <div>
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                            height: "300px",
                          }}
                        >
                          <p>
                            <img
                              className="mt-1"
                              src="../Images/ArokLogo.png"
                              width="200px"
                              alt="ArokLogo"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        AROK ENTERPRISE
                      </p>
                    </div>
                    <div>
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                            height: "300px",
                          }}
                        >
                          <p>
                            <img
                              className="mt-1"
                              src="../Images/anjalimetals.jpg"
                              width="200px"
                              alt="anjalimetals"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        ANJALI METALS
                      </p>
                    </div>

                    <div>
                      <div className="d-flex">
                        <div
                          className="card p-5 "
                          style={{
                            borderRadius: "10px",
                            border: "1px solid red",
                            height: "300px",
                          }}
                        >
                          <p>
                            <img
                              className="mt-1"
                              src="./Images/importmastery.jpg"
                              width="200px"
                              alt=" importmastery"
                            />
                          </p>
                        </div>
                        <div
                          className="mx-2 "
                          style={{
                            width: "50px",
                            height: "260px",
                            position: "relative",
                            top: "20px",

                            background:
                              "linear-gradient(to top, #db150e, #f6e58d)",
                          }}
                        >
                          <p
                            style={{
                              transform: "rotate(-90deg)",
                              position: "relative",
                              top: "200px",
                            }}
                            className="fw-bold text-white d-flex gap-2 fs-5 "
                          >
                            <span>Graphic</span>
                            <span>Design</span>
                          </p>
                        </div>
                      </div>
                      <p
                        className="fw-bold text-center logotext fs-5 mt-3 mb-4"
                        style={{ wordSpacing: "5px" }}
                      >
                        Mannix Import Mastery
                      </p>
                    </div>
                  </div>
                )}
                {/* Add more tab content as needed */}
              </div>
              <div className="tab-content d-md-none  d-sm-block ">
                {activeTab === "tab1" && (
                  <div
                    id="tab1"
                    className=" d-flex justify-content-center flex-wrap gap-3"
                  >
                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../web1.png"
                              alt="web1"
                            />
                          </p>
                          <p
                            className="fw-bold text-center logotext  fs-5 "
                            style={{ wordSpacing: "5px" }}
                          >
                            MINAXI PALACE HOTEL
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      {" "}
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../web2.png"
                              alt="web2"
                            />
                          </p>
                          <p
                            className="fw-bold text-center logotext fs-5"
                            style={{ wordSpacing: "5px" }}
                          >
                            BALAJI MANUFACTURING COMPANY
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../web3.png"
                              alt="web3
                              "
                            />
                          </p>
                          <p
                            className="fw-bold text-center logotext fs-5 "
                            style={{ wordSpacing: "5px" }}
                          >
                            BALAJI MOBILE CARE
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      {" "}
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../web4.png"
                              alt="web4"
                            />
                          </p>
                          <p
                            className="fw-bold text-center logotext fs-5 "
                            style={{ wordSpacing: "5px" }}
                          >
                            MAHAVEER LIGHTS
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../app1.png"
                              alt="app1"
                            />
                          </p>
                          <p
                            className="fw-bold text-center logotext fs-5 "
                            style={{ wordSpacing: "5px" }}
                          >
                            VIDYA BHAWAN SCHOOL
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="./Images/arokeenterprise.png"
                              alt="arokeenterprise"
                            />
                          </p>
                          <p
                            className="fw-bold text-center logotext fs-5 text-uppercase"
                            style={{ wordSpacing: "5px" }}
                          >
                            Arok Enterprise
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      {" "}
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="./Images/anjalimetals.png"
                              alt="anjalimetals"
                            />
                          </p>
                          <p
                            className="fw-bold text-center logotext fs-5 text-uppercase"
                            style={{ wordSpacing: "5px" }}
                          >
                            Anjali Metals
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div
                    id="tab2"
                    className="container d-flex justify-content-center flex-wrap gap-3"
                  >
                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../app2.png"
                              alt="app2"
                            />
                          </p>
                          <p
                            className="fw-bold text-center logotext fs-5"
                            style={{ wordSpacing: "5px" }}
                          >
                            THE NAISHA HYPERMART
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="d-flex">
                        <div>
                          <p>
                            <img
                              style={{
                                border: "1px solid red",
                                borderRadius: "5px",
                              }}
                              src="../app3.png"
                              alt="app3"
                            />
                          </p>
                          <p
                            className="fw-bold text-center logotext fs-5 "
                            style={{ wordSpacing: "5px" }}
                          >
                            MINAXI PALACE HOTEL
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "tab3" && (
                  <div
                    id="tab3"
                    className="container d-flex justify-content-center flex-wrap gap-3"
                  >
                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                        }}
                      >
                        <p>
                          <img
                            src="../logodesign2.png"
                            width="200px"
                            alt="logodesign2"
                          />
                        </p>
                      </div>
                      <p className="fw-bold logotext px-4 fs-5 ">
                        MINAXI PALACE
                      </p>
                    </div>

                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                        }}
                      >
                        <p>
                          <img
                            src="../logodesign3.png"
                            width="200px"
                            alt="logodesign3"
                          />
                        </p>
                      </div>
                      <p className="fw-bold  px-4 logotext fs-5 ">
                        VIDYABHAWAN
                      </p>
                    </div>

                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                        }}
                      >
                        <p>
                          <img
                            src="../logodesign4.png"
                            width="200px"
                            alt="logodesign4"
                          />
                        </p>
                      </div>

                      <p
                        className="fw-bold px-2 logotext fs-5 "
                        style={{ wordSpacing: "5px" }}
                      >
                        BALAJI MANUFACTURING
                      </p>
                    </div>

                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                        }}
                      >
                        <p>
                          <img
                            src="../logodesign5.png"
                            width="200px"
                            alt="logodesign5"
                          />
                        </p>
                      </div>

                      <p
                        className="fw-bold px-4 logotext fs-5 "
                        style={{ wordSpacing: "5px" }}
                      >
                        MAHAVEER LIGHTS
                      </p>
                    </div>

                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                        }}
                      >
                        <p>
                          <img
                            src="../logodesign6.png"
                            width="200px"
                            alt="logodesign6"
                          />
                        </p>
                      </div>
                      <p
                        className="fw-bold px-3 logotext fs-5 "
                        style={{ wordSpacing: "5px" }}
                      >
                        NAISHA HYPERMART
                      </p>
                    </div>

                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                          height: "300px",
                        }}
                      >
                        <p>
                          <img
                            className="mt-5"
                            src="../logodesign7.png"
                            width="200px"
                            alt="logodesign7"
                          />
                        </p>
                      </div>

                      <p
                        className="fw-bold px-3 logotext fs-5 "
                        style={{ wordSpacing: "5px" }}
                      >
                        BALAJI MOBILECARE
                      </p>
                    </div>

                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                        }}
                      >
                        <p>
                          <img
                            src="../Images/logodesign8.jpg"
                            width="200px"
                            alt="logodesign8"
                          />
                        </p>
                      </div>

                      <p
                        className="fw-bold px-4 logotext fs-5"
                        style={{ wordSpacing: "5px" }}
                      >
                        HELTON LIGHTS
                      </p>
                    </div>

                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                          height: "300px",
                        }}
                      >
                        <p>
                          <img
                            className="mt-1"
                            src="../Images/logodesign9.png"
                            width="200px"
                            alt="logodesign9"
                          />
                        </p>
                      </div>

                      <p
                        className="fw-bold px-1  logotext fs-5 "
                        style={{ wordSpacing: "5px" }}
                      >
                        T-CONNECT GADGETS
                      </p>
                    </div>
                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                          height: "300px",
                        }}
                      >
                        <p>
                          <img
                            className="mt-1"
                            src="../Images/ArokLogo.png"
                            width="200px"
                            alt="ArokLogo"
                          />
                        </p>
                      </div>

                      <p
                        className="fw-bold px-1  logotext fs-5 "
                        style={{ wordSpacing: "5px" }}
                      >
                        AROK ENTERPRISE
                      </p>
                    </div>
                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                          height: "300px",
                        }}
                      >
                        <p>
                          <img
                            className="mt-1"
                            src="../Images/anjalimetals.JPG"
                            width="200px"
                            alt="anjalimetals"
                          />
                        </p>
                      </div>

                      <p
                        className="fw-bold px-1  logotext fs-5 "
                        style={{ wordSpacing: "5px" }}
                      >
                        ANJALI METALS
                      </p>
                    </div>
                    <div className="text-center">
                      <div
                        className="card p-5 "
                        style={{
                          width: "300px",
                          borderRadius: "10px",
                          border: "1px solid red",
                          height: "300px",
                        }}
                      >
                        <p>
                          <img
                            className="mt-1"
                            src="./Images/importmastery.jpg"
                            width="200px"
                            alt="importmastery"
                          />
                        </p>
                      </div>

                      <p
                        className="fw-bold px-1  logotext fs-5 "
                        style={{ wordSpacing: "5px" }}
                      >
                        Mannix Import Mastery
                      </p>
                    </div>
                  </div>
                )}
                {/* Add more tab content as needed */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Portfolio;
